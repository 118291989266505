import PropTypes from "prop-types";
import Joi from "joi";
import React from "react"
import { FormError } from "..";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import InputPrependAppend from "../InputPrependAppend";
import { withAbort } from "../../../util";

/** 
 * @typedef PurposeModel
 * @property {String} id
 * @property {String} name
 * @property {Boolean} furtherDetailRequired
 */

/** 
 * @typedef PurposeCategoryModel
 * @property {String} name
 * @property {Array<PurposeModel>} purposes
 */

const purposeSchema = Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    furtherDetailRequired: Joi.boolean().allow(null).optional(),
});

const purposeCategorySchema = Joi.object({
    name: Joi.string().required(),
    purposes: Joi.array().min(1).items(purposeSchema).required()
});

/**
 * Validate the purposes array
 *
 * @param {*} purposes
 * @return {Array<PurposeModel>} 
 */
const validatePurposes = purposes => {
    const result = Joi.array().min(1).items(purposeCategorySchema).required().validate(purposes);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Purposes Response", result);
    }
    return purposes;
}

const Purposes = props => {

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    /** @type {[Array<PurposeCategoryModel>, React.Dispatch<Array<PurposeCategoryModel>>]} */
    const [purposeCategories, setPurposeCategories] = React.useState(null);

    const { url, onChange, onReady } = props;
    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("INIT_PURPOSE");
        fetchApi(url, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validatePurposes)
                        .then(setPurposeCategories)
                } else {
                    throw new FormError("Could not contact Purposes API");
                }
            })
            .then(() => onReady && onReady())
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [fetchApi, handleError, onReady, url, waitFor]);

    const handleOnChange = React.useCallback(value => {
        const allPurposes = purposeCategories.flatMap(purposeCategory => purposeCategory.purposes);
        onChange(allPurposes.find(purpose => purpose.id === value) || null)
    }, [purposeCategories, onChange]);

    return <React.Fragment>
        <InputPrependAppend type="select" label={props.label} id={props.id} value={props.value?.id || ""} onChange={handleOnChange} disabled={props.disabled} error={props.error}>
            <option disabled value=""> -- select an option -- </option>
            {purposeCategories?.map(purposeCategory =>
                <optgroup key={purposeCategory.name} label={purposeCategory.name}>
                    {purposeCategory.purposes.map(purpose =>
                        <option key={purpose.id} value={purpose.id}>{purpose.name}</option>
                    )}
                </optgroup>
            )}
        </InputPrependAppend>
    </React.Fragment>
}

Purposes.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onReady: PropTypes.func
}

export { purposeSchema };
export default React.memo(Purposes);
