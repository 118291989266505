import React from "react";
import NoticeArea from "../../../NoticeArea";
import SandboxButtons from "../../../FormItems/SandboxButtons"

const Sandbox = () => {
    return <React.Fragment>
        <h2>Blackboard Original sandbox courses</h2>
        <NoticeArea />
        <p>All members of staff can create a sandbox course with which to experiment with Blackboard features.</p>
        <ul>
            <li>You can only have one such course.</li>
            <li>Sandbox courses can <strong>not</strong> be used for teaching (any undergraduate or postgraduate taught students enrolled on it will be removed).</li>
            <li>Your sandbox will remain open while you remain at the University unless you explicitly remove it.</li>
        </ul>

        <div className="alert alert-info">
            <p>This page will create a Blackboard Original course.  
                If you want to try out a Blackboard Ultra course then <a href="/ultrasandbox">you can additionally create an Ultra sandbox here</a>.
                You may have one Original sandbox and one Ultra sandbox.
            </p>
        </div>

        <h3>Your sandbox course</h3>
        <SandboxButtons apiEndpoint="/sandbox" sandboxDescription="sandbox"/>
    </React.Fragment >
}

export default React.memo(Sandbox);