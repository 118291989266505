import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import { Context as Notice } from "../Notice";
import { NotLoggedInError } from "../Authentication";

/**
 * @callback HandleErrorFunction Handle the error gracefully
 * @param {Error} error error to handle
 * @returns {Void}
 */
/**
 * @typedef {Object} ErrorHandlerContext
 * @property {HandleErrorFunction} handleError the user object
 */
/** @type {React.Context<ErrorHandlerContext>} */
const Context = React.createContext(null);

/**
 * Error which is handled by showing an alert to the user
 *
 * @class HandledError
 * @extends {Error}
 */
class HandledError extends Error {
    /**
     * Creates an instance of HandledError.
     * @param {String} name
     * @param {String} message
     * @param {Error} [cause=null]
     * @param {*} [payload=null]
     * @memberof HandledError
     */
    constructor(name, message, cause = null, payload = null) {
        super(message);
        this.name = name;
        this.cause = cause;
        this.payload = payload;
    }
}

/**
 * Error which is handled, but does not show an alert to the user
 *
 * @class IgnoredError
 * @extends {Error}
 */
class IgnoredError extends Error {
    /**
     * Creates an instance of IgnoredError.
     * @param {String} name
     * @param {String} message
     * @param {Error} [cause=null]
     * @param {*} [payload=null]
     * @memberof IgnoredError
     */
    constructor(name, message, cause = null, payload = null) {
        super(message);
        this.name = name;
        this.cause = cause;
        this.payload = payload;
    }
}

/**
 * Provider for error handler context
 *
 * @param {Object} props
 * @return {*}
 */
const Provider = (props) => {
    const { danger } = React.useContext(Notice);

    const history = useHistory();

    /** @type {HandleErrorFunction} */
    const handleError = React.useCallback(
        (error) => {
            if (error instanceof HandledError) {
                danger(`${error.name}: ${error.message}`);
                console.log(
                    "Error",
                    error,
                    "Cause",
                    error.cause,
                    "Payload",
                    error.payload
                );
            } else if (error instanceof NotLoggedInError) {
                history.push("/");
            } else if (error instanceof IgnoredError) {
                console.log(
                    "Error",
                    error,
                    "Cause",
                    error.cause,
                    "Payload",
                    error.payload
                );
            } else if (error instanceof DOMException) {
                // These seem to happen in contexts that don't actually matter
                console.log("Ignored Error", error);
            }
            else {
                danger(
                    `An unexpected error occurred. Refreshing the page may fix the error. The error was "${error?.errorCode}".`
                );
                console.log("Error", error);
            }
        },
        [danger, history]
    );

    return (
        <Context.Provider value={{ handleError }}>
            {props.children}
        </Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export { Context, HandledError, IgnoredError };
export default React.memo(Provider);
