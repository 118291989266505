import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import { Waiting } from "../Store";

const Footer = () => {

    const { waiting } = React.useContext(Waiting)

    return <React.Fragment>
        <Row className="copyright">
            <Col xs="1">
                {waiting && <Spinner size="sm" />}
            </Col>
            <Col xs="11">
                <ul>
                    <li>
                        <a
                            href="http://www.southampton.ac.uk/about/conditions-policies/freedom-of-information.page"
                            rel="subsection"
                            title="publication scheme"
                        >
                            Freedom of information
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.southampton.ac.uk/about/conditions-policies/terms-conditions.page"
                            rel="subsection"
                            title="legal notices"
                        >
                            Terms &amp; Conditions
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.southampton.ac.uk/contact.page"
                            rel="subsection"
                            title="how to get in touch"
                        >
                            University Contacts
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.southampton.ac.uk/isolutions/getting_help/getting_help.php"
                            rel="subsection"
                            title="Get IT help"
                        >
                            IT Help
                        </a>
                    </li>
                </ul>
            </Col>
        </Row>
        <Row className="small trailer">
            
        </Row>
    </React.Fragment>
}


export default React.memo(Footer);