import PropTypes from "prop-types";
import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Authentication } from "../Store";

const ProtectedRoute = ({ role1, role2, referer, ...props }) => {
  const { user } = React.useContext(Authentication);

  const history = useHistory();

  if (user?.roles.includes(role1) || user?.roles.includes(role2)) {
    return <Route {...props} />;
  }

  if (Boolean(referer) && !Boolean(user)) {
    history.push("/", { referer });
    return null;
  }

  history.push("/");
  return null;
};

ProtectedRoute.propTypes = {
  referer: PropTypes.string,
  role: PropTypes.string,
};

export default React.memo(ProtectedRoute);
