import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";



const PagingControls = props => {
    const { pagingLinks, onSetUrl } = props;

    const showControls = Boolean(pagingLinks.totalCount);

    return showControls && <div className="input-group">
        <div className="input-group-prepend">
            <Button aria-label="first" disabled={pagingLinks.first === null}
                onClick={() => onSetUrl(pagingLinks.first)}>&lt;&lt;</Button>
            <Button aria-label="previous" disabled={pagingLinks.prev === null}
                onClick={() => onSetUrl(pagingLinks.prev)}>&lt;</Button>
        </div>
        <label className="form-control">
            Viewing {pagingLinks.startCount} to {pagingLinks.startCount + pagingLinks.currentCount - 1} of {pagingLinks.totalCount} rows.
        </label>
        <div className="input-group-append">
            <Button aria-label="next" disabled={pagingLinks.next === null}
                onClick={() => onSetUrl(pagingLinks.next)}>&gt;</Button>
            <Button aria-label="last" disabled={pagingLinks.last === null}
                onClick={() => onSetUrl(pagingLinks.last)}>&gt;&gt;</Button>
        </div>
    </div>;
}


PagingControls.propTypes = {
    pagingLinks: PropTypes.object.isRequired,
    onSetUrl: PropTypes.func.isRequired
}

export default React.memo(PagingControls);