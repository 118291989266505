import PropTypes from "prop-types";
import React from "react"
import Select from 'react-select';
import { FormError } from "..";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import { FormGroup, Label, Input, } from "reactstrap";

const removeCourseIdPrefix = (templateId) => {
    if (!templateId) {
        return '';
    }
    return templateId.startsWith("courseId:") ? templateId.replace("courseId:", "") : templateId;
}

const UltraCourseTemplates = props => {

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    const { url, onChange } = props;

    // Initialize the list of ultra templates
    const [ultraCourseTemplates, setUltraCourseTemplates] = React.useState(null);
    const initialize = () => {
        const stopWait = waitFor("INIT_ULTRA_TEMPLATES");
        fetchApi(url, { method: "GET" })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(setUltraCourseTemplates)
                } else {
                    throw new FormError("Could not contact Ultra Templates API");
                }
            })
            .catch(handleError)
            .then(stopWait)
    }
    React.useEffect(initialize, [fetchApi, handleError, onChange, url, waitFor]);

    // Initialize the list of courses to select from
    const [selectOptions, setSelectOptions] = React.useState(null);

    const updateSelectOptions = () => {
        setSelectOptions([
            { value: '', label: props.noValue },
            ...((ultraCourseTemplates || []).map(templateId => ({
                value: templateId,
                label: removeCourseIdPrefix(templateId),
            })))
        ]);
    }
    React.useEffect(updateSelectOptions, [ultraCourseTemplates]);

    const handleOnChange = React.useCallback(selection => {
        onChange(selection || null);
    }, [ultraCourseTemplates, onChange]);

    if (props.hidden) {
        return <React.Fragment />
    }

    if (props.disabled) {
        return <FormGroup>
            <Label for={props.id}>{props.label}</Label>
            <Input
                id={props.id}
                value={removeCourseIdPrefix(props.value)}
                disabled
            />
        </FormGroup>
    }

    return <FormGroup>
        <Label for={props.id}>{props.label}</Label>
        <Select
            id={props.id}
            inputId={props.id}
            defaultValue={selectOptions?.find(option => option.value === props.value)}
            options={selectOptions}
            onChange={handleOnChange}
        />
    </FormGroup>
}

UltraCourseTemplates.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool
}

export default React.memo(UltraCourseTemplates);
