import PropTypes from "prop-types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Button, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
import { BsLink } from "react-icons/bs";
import {
  IsUltraCourse,
  Departments,
  InputPrependAppend,
  Cohorts,
  AssignedTo,
  RequestOverview,
} from "../../../../FormItems";
import {
  Authentication,
  Waiting,
  ErrorHandler,
  Notice,
} from "../../../../Store";
import Joi from "joi";
import { HandledError } from "../../../../Store";
import { cohortSchema } from "../../../../FormItems/Cohorts";
import NoticeArea from "../../../../NoticeArea";
import Confirmation from "./Confirmation";
import { withAbort } from "../../../../../util";

/**
 * @typedef FormDataModel
 * @property {import("../../../../FormItems/Departments").DepartmentModel} department
 * @property {String} shortName
 * @property {String} name
 * @property {[String]} instructors
 * @property {[import("../../../../FormItems/Cohorts").Cohort]} cohorts
 * @property {String} requestedBy
 * @property {String} requestDate
 * @property {String} assignedTo
 * @property {String} rejectReason
 */

/** @type {FormDataModel} */
const initialFormData = {
  isUltraCourse: true,
  department: null,
  shortName: null,
  name: null,
  instructors: [],
  cohorts: [],
  requestedBy: null,
  requestDate: null,
  rejectReason: null,
  assignedTo: null,
};

const formDataSchema = Joi.object({
  isUltraCourse: Joi.boolean().required(),
  name: Joi.string().required(),
  shortName: Joi.string()
    .pattern(/^[A-Z0-9-]+/)
    .required(),
  department: Joi.object({
    code: Joi.string().required(),
    name: Joi.string().allow(null, "").optional(),
    templateCode: Joi.string().allow(null, "").optional(),
  })
    .allow(null)
    .optional(),
  instructors: Joi.array().items(Joi.string()).required(),
  cohorts: Joi.array().items(cohortSchema).optional(),
  requestedBy: Joi.string().allow(null, "").optional(),
  requestDate: Joi.date().allow(null, "").optional(),
  rejectReason: Joi.string().allow(null, "").optional(),
  assignedTo: Joi.string().allow(null, "").optional(),
});

/**
 * Validate the formData
 *
 * @param {*} templates
 * @return {Joi.ValidationResult}
 */
const validateFormData = (formData) =>
  formDataSchema.validate(formData, { abortEarly: false });

/**
 * @param {String} separator
 * @param {String[]} strings
 */
const joinStrings = (separator, strings) =>
  strings.filter((str) => Boolean(str)).join(separator);

class ProgrammeCourseFormError extends HandledError {
  constructor(message) {
    super("ProgrammeCourseFormError", message);
  }
}

/**
 * @param {Joi.ValidationResult} validation
 * @param {(String|Number)[]} path
 * @returns {Boolean}
 */
const hasError = (validation, path) =>
  Boolean(
    validation?.error?.details?.find((detail) =>
      path.every((segment, index) => segment === detail?.path[index])
    )
  );

const ProgrammeCourseForm = (props) => {
  const mounted = React.useRef();

  const { isApprovalView, back } = props;

  const { requestId } = useParams();

  const { handleError } = React.useContext(ErrorHandler);
  const { user, fetchApi } = React.useContext(Authentication);
  const { danger } = React.useContext(Notice);
  const { waitFor, waiting } = React.useContext(Waiting);

  const history = useHistory();

  /** @type {[FormDataModel, React.Dispatch<FormDataModel>]} */
  const [formData, setFormData] = React.useState(
    user?.departmentCode
      ? { ...initialFormData, department: { code: user.departmentCode } }
      : initialFormData
  );

  const [formReady, setFormReady] = React.useState(false);
  const [formItemsReady, setFormItemsReady] = React.useState({
    department: false,
  });
  const onDepartmentReady = React.useCallback(
    () =>
      Boolean(mounted.current) &&
      setFormItemsReady((prev) => ({ ...prev, department: true })),
    []
  );

  const onDepartmentChange = React.useCallback(
    (department) =>
      setFormData((prev) => ({ ...prev, department, cohorts: [] })),
    []
    );

    const onIsUltraCourse = React.useCallback(isUltraCourse => {
        setFormData(prev => ({
            ...prev,
            isUltraCourse
        }));
    }, []);

  const onNameChange = React.useCallback(
    (name) => setFormData((prev) => ({ ...prev, name })),
    []
  );
  const onShortNameChange = React.useCallback(
    (shortName) =>
      setFormData((prev) => ({
        ...prev,
        shortName: shortName
          ?.toUpperCase()
          .replace(/\s+/g, "-")
          .replace(/[^A-Z0-9-]/g, ""),
      })),
    []
  );
  // const onInstructorsChange = React.useCallback(
  //   (rawInstructors) =>
  //     setFormData((prev) => ({
  //       ...prev,
  //       instructors: rawInstructors?.split(/[\s\n,]+/),
  //     })),
  //   []
  // );
  const onCohortsChange = React.useCallback(
    (cohorts) => setFormData((prev) => ({ ...prev, cohorts })),
    []
  );
  const onAssignedToChange = React.useCallback(
    (assignedTo) => setFormData((prev) => ({ ...prev, assignedTo })),
    []
  );
  const onRejectReasonChange = React.useCallback(
    (rejectReason) => setFormData((prev) => ({ ...prev, rejectReason })),
    []
  );

  const [confirmation, setConfirmation] = React.useState(null);
  const closeConfirmation = React.useCallback(() => setConfirmation(null), []);
  const openConfirmation = (action) => () => setConfirmation({ action });

  const applyFormValues = React.useCallback(
    (course) => {
      if (course === null) {
        history.push(back, {
          action: "ERROR",
          data: "The request could not be found.",
        });
      } else if (course.state === 0) {
        setFormData((prev) => ({
          ...prev,
          shortName: course?.courseId?.replace(/^PROG-/, ""),
          name: course.courseName,
          isUltraCourse: course.isUltraCourse,
          cohorts: course?.cohorts.map((cohort) => ({
            id: cohort?.cohortId,
            year: cohort.yearOfStudy,
          })),
          instructors: course?.instructors,
          requestedBy: course?.requestedBy,
          requestDate: course?.requestDate,
          assignedTo: course?.assignedTo,
        }));
        setFormReady(true);
      } else {
        history.push(back, {
          action: "ERROR",
          data: "The request has already been resolved.",
        });
      }
    },
    [back, history]
  );

  const initialize = React.useCallback(
    () =>
      withAbort((signal) => {
        setFormReady(false);
        if (isApprovalView) {
          const stopWait = waitFor("AUTOFILL_FORM");
          fetchApi(`/programme-course-requests/${requestId}`, {
            method: "GET",
            signal,
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else if (response.status === 404) {
                return null;
              } else {
                throw new ProgrammeCourseFormError(
                  "Could not contact Requests API"
                );
              }
            })
            .then(applyFormValues)
            .catch(handleError)
            .finally(stopWait);
        } else if (Object.values(formItemsReady).every((ready) => ready)) {
          setFormReady(true);
        }
      }),
    [
      applyFormValues,
      fetchApi,
      formItemsReady,
      handleError,
      isApprovalView,
      requestId,
      waitFor,
    ]
  );
  React.useEffect(initialize, [initialize]);

  const [editMode, setEditMode] = React.useState(!isApprovalView);
  const enableEdit = () => setEditMode(true);
  const cancelEdit = React.useCallback(() => {
    setEditMode(false);
    initialize();
  }, [initialize]);

  const formEditable = Boolean(!waiting && editMode && formReady);
  const formSubmittable = Boolean(!waiting && formReady);

  /** @type {[Joi.ValidationResult, React.Dispatch<Joi.ValidationResult>]} */
  const [validation, setValidation] = React.useState(null);

  const postRequest = React.useCallback(
    (url, body) =>
      withAbort((signal) => {
        const stopWait = waitFor("PROGRAMME_REQUEST_RESOLVE");
        const result = validateFormData(formData);
        setValidation(result);

        if (Boolean(result.error)) {
          stopWait();
          closeConfirmation();
          danger(
            "Please check you have provided all the information required and then select Submit."
          );
        } else {
          const postData = {
            id: requestId ? parseInt(requestId) : null,
            isUltraCourse: formData.isUltraCourse,
            courseId: joinStrings("-", ["PROG", formData.shortName]),
            courseName: formData.name,
              instructors: formData.instructors,
            cohorts: formData.cohorts.map((cohort) => ({
              cohortId: cohort.id?.toString(),
              yearOfStudy: cohort.year?.toString(),
            })),
            departmentCode: formData.department.code
          };

          const headers = new Headers();
          headers.append("Content-Type", "application/json");
          fetchApi(url, {
            method: "POST",
            body: JSON.stringify(postData),
            headers,
            signal,
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new ProgrammeCourseFormError(
                  "Could not contact Requests API"
                );
              }
            })
            .then((data) =>
              history.push(back, {
                action: confirmation?.action,
                type: "PROGRAMME",
                data,
              })
            )
            .catch(handleError);
        }
      }),
    [
      back,
      closeConfirmation,
      confirmation,
      danger,
      fetchApi,
      formData,
      handleError,
      history,
      requestId,
      waitFor,
    ]
  );

  const handleRequest = React.useCallback(
    (e) => {
      e.preventDefault();
      postRequest("/programme-course-requests");
    },
    [postRequest]
  );

  const handleApprove = React.useCallback(
    (e) => {
      e.preventDefault();
      postRequest(`/programme-course-requests/${requestId}/approve`);
    },
    [postRequest, requestId]
  );

  const handleReject = React.useCallback(
    (e) => {
      e.preventDefault();
      waitFor("PROGRAMME_REQUEST_REJECT");

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      fetchApi(`/programme-course-requests/${requestId}/reject`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          reason: formData.rejectReason,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new ProgrammeCourseFormError(
              "Could not contact Requests API"
            );
          }
        })
        .then(() =>
          history.push(back, {
            action: confirmation?.action,
            type: "PROGRAMME",
            data: { courseId: "PROG-" + formData.shortName },
          })
        )
        .catch(handleError);
    },
    [
      back,
      confirmation,
      fetchApi,
      formData,
      handleError,
      history,
      requestId,
      waitFor,
    ]
  );

  const actionSwitch = React.useCallback(() => {
    switch (confirmation?.action) {
      case "REQUEST":
        return handleRequest;
      case "APPROVE":
        return handleApprove;
      case "REJECT":
        return handleReject;
      default:
        return () => {};
    }
  }, [confirmation?.action, handleApprove, handleReject, handleRequest]);

  return (
    <Form ref={mounted}>
      <Confirmation
        action={confirmation?.action}
        disabled={
          confirmation?.action === "REJECT" && !Boolean(formData.rejectReason)
        }
        hasCohorts={formData.cohorts.length > 0}
        handleAction={actionSwitch()}
        isOpen={Boolean(confirmation)}
        onClose={closeConfirmation}
      >
        {"REJECT" === confirmation?.action && (
          <InputPrependAppend
            id="rejectReason"
            label={
              <React.Fragment>
                <span className="text-danger">*</span>
                <span>Please provide a reason for this rejection.</span>
              </React.Fragment>
            }
            type="textarea"
            placeholder="required"
            value={formData.rejectReason}
            onChange={onRejectReasonChange}
          />
        )}
      </Confirmation>
      {!isApprovalView && <h1>Request a new Programme Course</h1>}
      <p>
        <Link to={props.back}>&lt; back to list</Link>
      </p>
      <NoticeArea />
      {isApprovalView && (
        <AssignedTo
          assignedTo={formData.assignedTo}
          onChange={onAssignedToChange}
          postUrl={`/programme-course-requests/${requestId}/assignself`}
        />
      )}
      {formReady && isApprovalView && (
        <RequestOverview
          type={formData.type}
          requestedBy={formData.requestedBy}
          requestDate={formData.requestDate}
        />
      )}
      <IsUltraCourse
        id="isUltraCourse"
        label="Do you want to create an ultra or original course?"
        value={formData.isUltraCourse}
        onChange={onIsUltraCourse}
        disabled={!formEditable}
              error={hasError(validation, ["isUltraCourse"]) ? "Please choose whether this is an Ultra or Original course" : null}
      />
      <InputPrependAppend
        id="name"
        label="Programme title"
        type="text"
        placeholder="Descriptive programme title"
        value={formData.name}
        onChange={onNameChange}
        disabled={!formEditable}
        error={
          hasError(validation, ["name"])
            ? "You must provide a valid course title"
            : null
        }
      />
      <InputPrependAppend
        id="shortName"
        label="Programme ID"
        type="text"
        placeholder="PROGRAMME-ID"
        separator="-"
        prepend="PROG"
        description="*Uppercase numbers and dashes only"
        value={formData.shortName}
        onChange={onShortNameChange}
        disabled={!formEditable}
        error={
          hasError(validation, ["shortName"])
            ? "You must provide a valid course ID"
            : null
        }
      />
      {!isApprovalView && (
        <Departments
          id="department"
          url="/faculties"
          value={formData.department}
          disabled={!formEditable}
          onChange={onDepartmentChange}
          onReady={onDepartmentReady}
          error={
            hasError(validation, ["department"])
              ? "You must provide a valid faculty, school or department"
              : null
          }
        />
      )}
      <hr />
      <Cohorts
        id="cohorts"
        label={
            <React.Fragment>
                Select student cohorts to include in this Blackboard programme course.
                <br />
                <Badge color="info"><BsLink /></Badge> indicate cohorts that are already linked to another programme course.
            </React.Fragment>
        }
        departmentCode={formData.department?.code}
        filterDepartments={!isApprovalView}
        disabled={!formEditable}
        onChange={onCohortsChange}
        value={formData.cohorts}
        error={
          hasError(validation, ["cohorts"])
            ? "You must provide at least one student cohort"
            : null
        }
        currentCourseId={formData.shortName}
      />
      {isApprovalView && (
        <React.Fragment>
          <Button
            onClick={openConfirmation("REJECT")}
            type="button"
            color="danger"
            disabled={!formSubmittable}
          >
            Reject
          </Button>
          <span className="float-right">
            <Button
              type="button"
              color="secondary"
              onClick={editMode ? cancelEdit : enableEdit}
              disabled={!formSubmittable}
            >
              {editMode ? "Cancel" : "Edit"}
            </Button>{" "}
            <Button
              type="button"
              color="success"
              onClick={openConfirmation("APPROVE")}
              disabled={!formSubmittable}
            >
              {editMode ? "Submit" : "Approve"}
            </Button>
          </span>
        </React.Fragment>
      )}
      {!isApprovalView && (
        <Button
          onClick={openConfirmation("REQUEST")}
          className="float-right"
          type="button"
          color="primary"
          disabled={!formSubmittable}
        >
          Submit
        </Button>
      )}
    </Form>
  );
};

ProgrammeCourseForm.propTypes = {
  back: PropTypes.string.isRequired,
  isApprovalView: PropTypes.bool,
};

export default React.memo(ProgrammeCourseForm);
