import PropTypes from "prop-types";
import Joi from "joi";
import React from "react"
import Select from 'react-select';
import { FormError } from "..";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import { FormGroup, Label, Input, } from "reactstrap";
import { HelpIcon } from '..';

/** 
 * @typedef CourseModel
 * @property {String} id
 * @property {String} courseId
 * @property {String} link
 * @property {String} name
 * @property {String} termId
 * @property {String} termInfo
 */

const courseSchema = Joi.object({
    id: Joi.string().required(),
    courseId: Joi.string().required(),
    name: Joi.string().required(),
    link: Joi.string().allow(null).optional(),
    termId: Joi.string().allow(null).optional(),
    termInfo: Joi.string().allow(null).optional()
});

/**
 * Validate the array
 *
 * @param {*} templates
 * @return {Array<CourseModel>} 
 */
const validateCourses = courses => {
    const result = Joi.array().items(courseSchema).validate(courses);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Courses Response", result);
    }
    return courses;
}

const CourseToCopy = props => {

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    /** @type {[Array<CourseModel>, React.Dispatch<Array<CourseModel>>]} */
    const [courses, setCourses] = React.useState(null);

    const { url, onChange, value } = props;
    // Initialize the list of courses
    const initialize = () => {
        const stopWait = waitFor("INIT_INSTRUCTOR_COURSES");
        fetchApi(url, { method: "GET" })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateCourses)
                        .then(setCourses)
                } else {
                    throw new FormError("Could not contact Instructor Courses API");
                }
            })
            .catch(handleError)
            .then(stopWait)
    }
    React.useEffect(initialize, [fetchApi, handleError, onChange, url, waitFor]);

    // Initialize the list of courses to select from
    const [selectOptions, setSelectOptions] = React.useState(null);
    const updateSelectOptions = () => {
        const options = courses?.map(x => {
            return {
                value: x.courseId,
                label: `${x.courseId} (${x.name})`
            };
        });
        setSelectOptions(options);
    }
    React.useEffect(updateSelectOptions, [courses]);

    // Set the selected option
    const [selectedOption, setSelectedOption] = React.useState(null);
    const updateSelectedOption = () => {
        const option = selectOptions?.find(x => x.value === value?.courseId);
        setSelectedOption(option);
    }
    React.useEffect(updateSelectedOption, [selectOptions, value]);

    // Set the term information
    const [termInfo, setTermInfo] = React.useState(null);

    // Handle the change - find the matching course and emit that
    const handleOnChange = React.useCallback(selection => {
        const course = courses.find(x => x.courseId === selection.value);
        onChange(course || null);
        setTermInfo(course ? course.termInfo : "Unknown Term");
    }, [courses, onChange]);

    if (props.hidden)
        return <React.Fragment />
    else if (props.disabled)
        return <FormGroup>
            <Label for={props.id}>{props.label}</Label>
            <Input
                id={props.id}
                value={props.value?.courseId}
                disabled
            />
        </FormGroup>
    else
        return <FormGroup>
            <Label for={props.id}>{props.label}{' '}
                <HelpIcon id="courseToCopyHelp">
                You can select any course that you are an instructor on.
                Type into the box to filter the list based on course ID or title.
                </HelpIcon>
            </Label>
            <Select
                inputId={props.id}
                defaultValue={selectedOption}
                options={selectOptions}
                onChange={handleOnChange}
            />
            <div style={{ marginLeft: '9px' }}>
                <em>Term:</em> {termInfo || "Select a course to see term info"}
            </div>
        </FormGroup>
}

CourseToCopy.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool
}

export { courseSchema };
export default React.memo(CourseToCopy);
