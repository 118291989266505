import PropTypes from "prop-types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Badge, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { BsLink } from "react-icons/bs";

import {
  Departments,
  InputPrependAppend,
  Cohorts,
} from "../../../../FormItems";
import {
  Authentication,
  Waiting,
  ErrorHandler,
  Notice,
} from "../../../../Store";
import Joi from "joi";
import { HandledError } from "../../../../Store";
import { cohortSchema } from "../../../../FormItems/Cohorts";
import NoticeArea from "../../../../NoticeArea";
import Confirmation from "../ProgrammeCourseForm/Confirmation";
import { withAbort } from "../../../../../util";
import PendingTag from "../../../../FormItems/PendingTag";

/**
 * @typedef FormDataModel
 * @property {import("../../../../FormItems/Departments").DepartmentModel} department
 * @property {String} shortName
 * @property {String} name
 * @property {[String]} instructors
 * @property {[import("../../../../FormItems/Cohorts").Cohort]} cohorts
 * @property {String} requestedBy
 * @property {String} requestDate
 * @property {String} assignedTo
 * @property {Number} state
 * @property {String} rejectReason
 */

/** @type {FormDataModel} */
const initialFormData = {
    department: null,
    shortName: null,
    name: null,
    instructors: [],
    cohorts: [],
    requestedBy: null,
    state: null,
    requestDate: null,
    rejectReason: null
};

const formDataSchema = Joi.object({
    name: Joi.string().required(),
    shortName: Joi.string()
        .pattern(/^[A-Z0-9-]+/)
        .required(),
    department: Joi.object({
        code: Joi.string().required(),
        name: Joi.string().allow(null, "").optional(),
        templateCode: Joi.string().allow(null, "").optional(),
    })
        .allow(null)
        .optional(),
    instructors: Joi.array().items(Joi.string()).required(),
    cohorts: Joi.array().items(cohortSchema).optional(),
    requestedBy: Joi.string().allow(null, "").optional(),
    requestDate: Joi.date().allow(null, "").optional(),
    state: Joi.number().required(),
    rejectReason: Joi.string().allow(null, "").optional(),
    isAllowedToEdit: Joi.boolean().optional()
    // assignedTo: Joi.string().allow(null, "").optional()
});

/**
 * Validate the formData
 *
 * @param {*} templates
 * @return {Joi.ValidationResult}
 */
const validateFormData = (formData) =>
    formDataSchema.validate(formData, { abortEarly: false });

/**
 * @param {String} separator
 * @param {String[]} strings
 */
const joinStrings = (separator, strings) =>
    strings.filter((str) => Boolean(str)).join(separator);

class ProgrammeCourseViewError extends HandledError {
    constructor(message) {
        super("ProgrammeCourseViewError", message);
    }
}

/**
 * @param {Joi.ValidationResult} validation
 * @param {(String|Number)[]} path
 * @returns {Boolean}
 */
const hasError = (validation, path) =>
    Boolean(
        validation?.error?.details?.find((detail) =>
            path.every((segment, index) => segment === detail?.path[index])
        )
    );


const ProgrammeCourseView = (props) => {
    const mounted = React.useRef();

    const { back } = props;
    const { requestId } = useParams();
    const { handleError } = React.useContext(ErrorHandler);
    const { user, fetchApi } = React.useContext(Authentication);
    const { danger, info } = React.useContext(Notice);
    const { waitFor, waiting } = React.useContext(Waiting);

    const history = useHistory();

    /** @type {[FormDataModel, React.Dispatch<FormDataModel>]} */
    const [formData, setFormData] = React.useState(
        user?.departmentCode
            ? { ...initialFormData, department: { code: user.departmentCode } }
            : initialFormData
    );

    const [formReady, setFormReady] = React.useState(false);
    const [formRequestReady, setFormRequestReady] = React.useState(false);
    const [formCourseReady, setFormCourseReady] = React.useState(false);
    const [formItemsReady, setFormItemsReady] = React.useState({
        department: false,
    });
    const onNameChange = React.useCallback(
        (name) => setFormData((prev) => ({ ...prev, name })),
        []
    );
    const onShortNameChange = React.useCallback(
        (shortName) =>
            setFormData((prev) => ({
                ...prev,
                shortName: shortName
                    ?.toUpperCase()
                    .replace(/\s+/g, "-")
                    .replace(/[^A-Z0-9-]/g, ""),
            })),
        []
    );

    const onCohortsChange = React.useCallback(
        (cohorts) => setFormData((prev) => ({ ...prev, cohorts })),
        []
    );


    const [confirmation, setConfirmation] = React.useState(null);
    const closeConfirmation = React.useCallback(() => setConfirmation(null), []);
    const openConfirmation = (action) => () => setConfirmation({ action });

    const applyFormValues = React.useCallback(
        (course) => {
            if (course === null) {
                history.push(back, {
                    action: "ERROR",
                    data: "The request could not be found.",
                });
            } else {
                setFormCourseReady(false)
                setFormData((prev) => ({
                    ...prev,
                    shortName: course?.courseId?.replace(/^PROG-/, ""),
                    name: course.courseName,
                    cohorts: course?.cohorts.map((cohort) => ({
                        id: cohort?.cohortId,
                        year: cohort.yearOfStudy,
                    })),
                    instructors: course?.instructors,
                    requestedBy: course?.requestedBy,
                    requestDate: course?.requestDate,
                    state: course?.state,
                    department: {
                        code: course?.departmentCode
                    },
                    isAllowedToEdit: course?.isAllowedToEdit
                    // assignedTo: course?.assignedTo
                }));
                setFormCourseReady(true);
            }
        },
        [back, history]
    );

    const initialize = React.useCallback(
        () =>
            withAbort((signal) => {
                setFormRequestReady(false);
                const stopWait = waitFor("AUTOFILL_FORM");
                fetchApi(`/programme-course-requests/${requestId}`, {
                    method: "GET",
                    signal,
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else if (response.status === 404) {
                            return null;
                        } else {
                            throw new ProgrammeCourseViewError(
                                "Could not contact Requests API"
                            );
                        }
                    })
                    .then(applyFormValues)
                    .catch(handleError)
                    .finally(stopWait);
            }),
        [
            applyFormValues,
            fetchApi,
            formItemsReady,
            handleError,
            requestId,
            waitFor,
        ]
    );
    React.useEffect(initialize, [initialize]);

    React.useEffect(() => {
        if (formRequestReady && formCourseReady) {
            setFormReady(true);
        }
    }, [formRequestReady, formCourseReady]);


    const [editMode, setEditMode] = React.useState(false);
    const enableEdit = () => setEditMode(true);
    const cancelEdit = React.useCallback(() => {
        setEditMode(false);
        initialize();
    }, [initialize]);


    //formEditable has been hardcoded to false. In case this
    //is being made editable in future it has to be made into a type of a interactive boolean;
    const formEditable = false;
    // const formSubmittable = Boolean(!waiting && formReady);

    /** @type {[Joi.ValidationResult, React.Dispatch<Joi.ValidationResult>]} */
    const [validation, setValidation] = React.useState(null);

    const putRequest = React.useCallback(
        (url, body) =>
            withAbort((signal) => {
                const stopWait = waitFor("PROGRAMME_REQUEST_RESOLVE");
                const result = validateFormData(formData);
                setValidation(result);

                if (Boolean(result.error)) {
                    stopWait();
                    closeConfirmation();
                    danger(
                        "Please check you have provided all the information required and then select Submit."
                    );
                } else {
                    const bodyData = {
                        id: requestId ? parseInt(requestId) : null,
                        courseId: joinStrings("-", ["PROG", formData.shortName]),
                        courseName: formData.name,
                        departmentCode: formData.department.code,
                        instructors: formData.instructors,
                        cohorts: formData.cohorts.map((cohort) => ({
                            cohortId: cohort.id?.toString(),
                            yearOfStudy: cohort.year?.toString(),
                        })),
                    };

                    const headers = new Headers();
                    headers.append("Content-Type", "application/json");
                    fetchApi(url, {
                        method: "PUT",
                        body: JSON.stringify(bodyData),
                        headers,
                        signal,
                    })
                        .then((response) => {
                            if (response.ok) {
                                closeConfirmation();
                                info("The course has been updated");
                                setEditMode(false);
                            }
                            else {
                                throw new ProgrammeCourseViewError(
                                    "Could not contact Requests API"
                                );
                            }
                        })
                        .catch(handleError)
                        .finally(stopWait);
                }
            }),
        [
            back,
            closeConfirmation,
            confirmation,
            danger,
            fetchApi,
            formData,
            handleError,
            history,
            requestId,
            waitFor,
        ]
    );


    const handleSave = React.useCallback(
        (e) => {
            e.preventDefault();
            putRequest(`/programme-course-requests/${requestId}`);
        },
        [putRequest, requestId]
    );


    return (
        <Form ref={mounted}>
            <Confirmation
                action={"UPDATE"}
                disabled={false}
                handleAction={handleSave}
                isOpen={Boolean(confirmation)}
                onClose={closeConfirmation}
                suppressUndoWarning
            />
            <p>
                <Link to={props.back}>&lt; back to list</Link>
            </p>
            <NoticeArea />
            <p>
                Course instructors and the original requester can change some course details via this
                form.  Otherwise, to request any changes to an existing course,
                email <a href="serviceline@soton.ac.uk">serviceline@soton.ac.uk</a> and
                include the Blackboard Course ID.
            </p>
            <PendingTag value={formData.state} />
            <div className="text-right">
                {editMode &&
                    <React.Fragment>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={cancelEdit}
                        >
                            Cancel
                        </Button>
                        {" "}
                        <Button
                            type="button"
                            color="danger"
                            onClick={openConfirmation("UPDATE")}
                        >
                            Save
                        </Button>
                    </React.Fragment>
                }
                {formData.isAllowedToEdit && !editMode &&
                    <Button
                        type="button"
                        color="primary"
                        onClick={enableEdit}
                    >
                        Edit
                    </Button>
                }
            </div>
            <InputPrependAppend
                id="name"
                label="Programme title"
                type="text"
                placeholder="Descriptive programme title"
                value={formData.name}
                onChange={onNameChange}
                disabled={!editMode}
                error={
                    hasError(validation, ["name"])
                        ? "You must provide a valid course title"
                        : null
                }
            />
            <InputPrependAppend
                id="shortName"
                label="Programme ID"
                type="text"
                placeholder="PROGRAMME-ID"
                separator="-"
                prepend="PROG"
                description="*Uppercase numbers and dashes only"
                value={formData.shortName}
                onChange={onShortNameChange}
                disabled={!formEditable}
                error={
                    hasError(validation, ["shortName"])
                        ? "You must provide a valid course ID"
                        : null
                }
            />

            <Departments
                id="department"
                url="/faculties"
                value={formData.department}
                disabled={true}
            />
            

            <hr />

            <Cohorts
                id="cohorts"
                label={
                    <React.Fragment>
                        Student cohorts included in enrollments on this Blackboard programme course.
                        <br />
                        <Badge color="info"><BsLink /></Badge> indicates a cohort that is already linked to another programme course.
                    </React.Fragment>
                }
                includedLabel={
                    <React.Fragment>
                        (course instructors, school office admins and the original requester can edit this form to change enrolments)
                    </React.Fragment>
                }
                departmentCode={formData.department?.code}
                filterDepartments={false}
                disabled={!editMode}
                onChange={onCohortsChange}
                value={formData.cohorts}
                error={
                    hasError(validation, ["cohorts"])
                        ? "You must provide at least one student cohort"
                        : null
                }
                currentCourseId={formData.shortName}
            />

            <span className="float-right">
                {editMode &&
                    <React.Fragment>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={cancelEdit}
                        >
                            Cancel
                        </Button>
                        {" "}
                        <Button
                            type="button"
                            color="danger"
                            onClick={openConfirmation("UPDATE")}
                        >
                            Save
                        </Button>
                    </React.Fragment>
                }
                {formData.isAllowedToEdit && !editMode &&
                    <Button
                        type="button"
                        color="primary"
                        onClick={enableEdit}
                    >
                        Edit
                    </Button>
                }
            </span>
        </Form>
    );
};

ProgrammeCourseView.propTypes = {
    back: PropTypes.string.isRequired
};

export default React.memo(ProgrammeCourseView);
