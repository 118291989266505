import React from "react";
import { Col, Row, Button } from "reactstrap";
import ToolbarLaunch from "./ToolbarLaunch";
import { Authentication, ErrorHandler } from "../Store";

const Accessibility = () => {

    const { user, logout } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const safeLogout = React.useCallback(() => logout().catch(handleError), [handleError, logout]);

    return <Row className="account">
        <Col xs="auto">{user && <React.Fragment>
            You are logged in as {user.displayName} ({user.username})
            <Button
                style={ {color: "black", fontWeight: "bold", paddingTop: 2 }}
                size="sm"
                color="link"
                onClick={safeLogout}>Sign out</Button>
        </React.Fragment>}</Col>
        <Col><ToolbarLaunch /></Col>
    </Row>
}

export default React.memo(Accessibility);