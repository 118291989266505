import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Notice, Authentication, Waiting, ErrorHandler, HandledError } from "../../Store";
import Joi from "joi"
import useInterval from "use-interval";
import usePrevious from "use-previous";
import { withAbort } from "../../../util";

class SandboxError extends HandledError {
    constructor(message, validation = null) {
        super("SandboxError", message, null, validation);
    }
}

/** 
 * @typedef SandboxModel
 * @property {String} id
 * @property {String} courseId
 * @property {String} link
 * @property {Boolean} pendingDeletion
 */

const sandboxSchema = Joi.object({
    id: Joi.string().required(),
    courseId: Joi.string().required(),
    link: Joi.string().uri().required(),
    pendingDeletion: Joi.boolean().optional()
});

/**
 * Validate the sandbox object
 *
 * @param {*} sandbox
 * @return {SandboxModel} 
 */
const validateSandbox = sandbox => {
    const result = sandboxSchema.validate(sandbox);
    if (Boolean(result.error)) {
        throw new SandboxError("Malformed Response", result);
    }
    return sandbox;
}

const SandboxButtons = props => {
    const { apiEndpoint, sandboxDescription } = props;

    const { waiting, waitFor } = React.useContext(Waiting)

    const { fetchApi } = React.useContext(Authentication);

    const { info } = React.useContext(Notice);

    const { handleError } = React.useContext(ErrorHandler);

    /** @type {[SandboxModel, React.Dispatch<SandboxModel>]} */
    const [sandbox, setSandbox] = React.useState(null);
    /** @type {SandboxModel} */
    const prevSandbox = usePrevious(sandbox);

    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

    const openDeleteConfirmation = () => setDeleteConfirmation(true);

    const closeDeleteConfirmation = () => setDeleteConfirmation(false);

    const getSandbox = React.useCallback((signal) => {
        const stopWait = waitFor("GET_SANDBOX");
        fetchApi(apiEndpoint, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateSandbox)
                        .then(setSandbox);
                }
                if (response.status === 404) {
                    setSandbox(null);
                } else {
                    throw new SandboxError("Could not contact API");
                }
            })
            .catch(handleError)
            .then(stopWait)
    }, [fetchApi, waitFor, handleError]);

    const createSandbox = React.useCallback(() => {
        const stopWait = waitFor("CREATE_SANDBOX");
        fetchApi(apiEndpoint, { method: "POST" })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateSandbox)
                } else {
                    throw new SandboxError("Could not contact API");
                }
            })
            .then(setSandbox)
            .then(() => info(`Your ${sandboxDescription} has been created.`))
            .catch(handleError)
            .then(stopWait)
    }, [fetchApi, waitFor, info, handleError]);

    const deleteSandbox = React.useCallback(() => {
        const stopWait = waitFor("DELETE_SANDBOX");
        fetchApi(apiEndpoint, { method: "DELETE" })
            .then(response => {
                if (response.ok) {
                    setSandbox(sandbox => {
                        return {
                            ...sandbox,
                            pendingDeletion: true,
                        }
                    });
                } else {
                    throw new SandboxError("Could not contact API");
                }
            })
            .then(() => info(`Your ${sandboxDescription} has been queued for deletion. Please wait for changes to be applied.`))
            .then(closeDeleteConfirmation)
            .catch(handleError)
            .finally(stopWait)
    }, [fetchApi, waitFor, info, handleError]);

    const initialize = () => withAbort(getSandbox);
    React.useEffect(initialize, [getSandbox]);

    // After deletion poll for the sandbox to be deleted
    useInterval(getSandbox, sandbox?.pendingDeletion ? 1000 : null);

    const notifyDeletedSandbox = () => {
        if (prevSandbox?.pendingDeletion && !sandbox?.pendingDeletion) {
            info(`Your ${sandboxDescription} has been deleted. You can create a new one by using the button below.`)
        }
    }
    useEffect(notifyDeletedSandbox, [sandbox, prevSandbox, info]);

    const emptySandbox = <React.Fragment>
        <p>You do not currently have a {sandboxDescription}, but you can create one.</p>
        <br />
        <Button
            color="primary"
            disabled={waiting || sandbox?.pendingDeletion}
            onClick={createSandbox}>Create {sandboxDescription}</Button>
    </React.Fragment>

    const existingSandbox = <React.Fragment>
        {sandbox?.pendingDeletion
            ? <p>Your {sandboxDescription} <strong>{sandbox?.courseId}</strong> is pending deletion. Please wait for changed to be applied. </p>
            : <p>You already have an existing {sandboxDescription} <strong>{sandbox?.courseId}</strong>. If you want to reset the course you can delete it, and then recreate it. </p>
        }
        <br />
        <Button
            color="default"
            disabled={waiting || sandbox?.pendingDeletion}
            href={sandbox?.link}
            target="_blank">Go to your  {sandboxDescription}</Button>{' '}
        <Button
            color="danger"
            disabled={waiting || sandbox?.pendingDeletion}
            onClick={openDeleteConfirmation}>Delete  {sandboxDescription}</Button>
    </React.Fragment>

    return <React.Fragment>
        <Modal isOpen={deleteConfirmation} toggle={closeDeleteConfirmation}>
            <ModalHeader>Delete confirmation</ModalHeader>
            <ModalBody>
                <p>Are you sure you want to delete <strong>{sandbox?.courseId}</strong>?</p>
                <Alert color="warning">
                    This cannot be undone!
                </Alert>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={closeDeleteConfirmation}>Cancel</Button>
                <Button color="danger" disabled={waiting || sandbox?.pendingDeletion} onClick={deleteSandbox}>Delete</Button>
            </ModalFooter>
        </Modal>

        {Boolean(sandbox) ? existingSandbox : emptySandbox}
    </React.Fragment >
}


SandboxButtons.propTypes = {
    apiEndpoint: PropTypes.string.isRequired,
    sandboxDescription: PropTypes.string.isRequired
}

export default React.memo(SandboxButtons);