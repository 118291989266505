import React, { useRef } from "react";
import { Form, Button, Collapse, FormGroup } from "reactstrap";
import Joi from "joi";
import { ErrorHandler, Waiting, Authentication, Notice } from "../../../Store";
import {
    IsUltraCourse,
    CourseToCopy,
    Departments,
    DepartmentTemplates,
    Discuss,
    InputPrependAppend,
    PurposeOther,    
    Purposes,
    Semesters,
    AcademicYears,
    UserData,
    IsCopyRequest,
} from "../../../FormItems"
import NoticeArea from "../../../NoticeArea";
import { HandledError } from "../../../Store";

/** 
 * @typedef CoursesModel
 * @property {Number} id
 * @property {Boolean} isUltraCourse
 * @property {String} type New or Copy
 * @property {String} courseId
 * @property {String} courseName
 * @property {String} departmentCode
 * @property {String} departmentTemplateCode
 * @property {String} academicYearCode
 * @property {String} semesterCode
 * @property {String} courseUse
 * @property {String} courseUseOtherDetails
 * @property {String} containsUserData
 * @property {String} wouldLikeToDiscuss
 * @property {String} additionalNotes
 * @property {String} copyOfCourseId
 * @property {String} copyOfCourseName
 * @property {String} requestDate
 * @property {String} requestedBy
 * @property {String} state
 */

/** 
 * @typedef FormDataModel
 * @property {Boolean} isUltraCourse
 * @property {String} type New or Copy
 * @property {import("../../../FormItems/AcademicYears").AcademicYearModel} academicYear
 * @property {String} name
 * @property {String} shortName
 * @property {import("../../../FormItems/Departments").DepartmentModel} department
 * @property {import("../../../FormItems/DepartmentTemplates").DepartmentTemplateModel} departmentTemplate
 * @property {import("../../../FormItems/Semesters").SemesterModel} semester
 * @property {import("../../../FormItems/Purposes").PurposeModel} purpose
 * @property {String} purposeOther
 * @property {Boolean} userData
 * @property {Boolean} discuss
 * @property {String} comments
 * @property {String} copyOfCourseId
 * @property {String} copyOfCourseName
 * 
 */

 const formDataSchema = Joi.object({
    isUltraCourse: Joi.boolean().required(),
    type: Joi.string().valid("New", "Copy").required(),
    academicYear: Joi.object({
         code: Joi.string().required(),
         name: Joi.string().allow(null).optional(),
         isDefault: Joi.boolean().allow(null).optional()
     }).allow(null).optional(),
    name: Joi.string().required(),
    shortName: Joi.string().pattern(/^[A-Z0-9-]+/).required(),
    department: Joi.object({
        code: Joi.string().required(),
        name: Joi.string().allow(null).optional(),
        templateCode: Joi.string().allow(null).optional()
    }).required(),
    departmentTemplate: Joi.object({
        code: Joi.ref("...department.templateCode"),
        name: Joi.string().allow(null).optional()
    }).allow(null).optional(),
    semester: Joi.object({
        id: Joi.string().required(),
        name: Joi.string().allow(null).optional()
    }).allow(null).optional(),
    purpose: Joi.object({
        id: Joi.string().required(),
        name: Joi.string().allow(null).optional(),
        furtherDetailRequired: Joi.boolean().allow(null).optional()
    }).required(),
    purposeOther: Joi.when('purpose.furtherDetailRequired', {
        is: true,
        then: Joi.string().required(),
        otherwise: Joi.allow(null).optional()
    }),
    userData: Joi.boolean().required(),
    discuss: Joi.boolean().allow(null).optional(),
    comments: Joi.string().allow(null, "").optional(),
    copyOfCourse: Joi.when('isCopy', {
        is: true,
        then: Joi.object({
            courseId: Joi.string().required(),
            name: Joi.string().required()
        }).unknown(true),
        otherwise: Joi.allow(null).optional()
    })
});

/**
 * Validate the formData
 *
 * @param {*} templates
 * @return {Joi.ValidationResult} 
 */
const validateFormData = formData => formDataSchema.validate(formData, { abortEarly: false });

/** @type {FormDataModel} */
const initialFormData = {
    isUltraCourse: false,
    type: "New",
    academicYear: null,
    name: null,
    shortName: null,
    department: null,
    departmentTemplate: null,
    semester: null,
    purpose: null,
    purposeOther: null,
    userData: null,
    discuss: null,
    comments: null,
    copyOfCourse: null
}

/**
 * @param {String} separator
 * @param {String[]} strings
 */
const joinStrings = (separator, strings) => strings.filter(str => Boolean(str)).join(separator);

class CoursesError extends HandledError {
    constructor(message) {
        super("CoursesError", message);
    }
}

/**
 * @param {Joi.ValidationResult} validation
 * @param {(String|Number)[]} path
 * @returns {Boolean}
 */
const hasError = (validation, path) => Boolean(validation?.error?.details?.find(detail => path.every((segment, index) => segment === detail?.path[index])));

const extractFromRegex = (regex, str) => {
    if (!str) return null;
    const match = regex.exec(str);
    return (match === null) ? str : match[1];
}

const trimAcademicYearFromPrefix = str => extractFromRegex(/^\d\d-\d\d-(.*)$/, str);

const trimAcademicYearFromSuffix = str => extractFromRegex(/^(.*)-\d\d-\d\d$/, str);


const Courses = () => {

    const { handleError } = React.useContext(ErrorHandler);
    const { user, fetchApi } = React.useContext(Authentication);
    const { info, danger } = React.useContext(Notice);
    const { waitFor, waiting } = React.useContext(Waiting);
    const courseShortNameRef = useRef(null);
    const academicYearCodeRef = useRef(null);

    /** @type {[FormDataModel, React.Dispatch<FormDataModel>]} */
    const [formData, setFormData] = React.useState(user?.departmentCode ? {...initialFormData, department: {code: user.departmentCode}} : initialFormData);

    const calcCourseShortName = (courseShortName, isUltraCourse, isCopy, academicYearCode) => {
        if (!courseShortName || !isCopy) {
            return courseShortName;
        }
        if (isUltraCourse && courseShortName.startsWith('CM-')) {
            courseShortName = courseShortName.slice(3);
        }
        courseShortName = ['-ULTRA', '-COPY'].reduce((name, postfix) => name.endsWith(postfix) ?
            name.slice(0, -postfix.length) : name,
            courseShortName);
        if (academicYearCode) {
            return courseShortName;
        }
        return courseShortName + (isUltraCourse ? '' : '-COPY');
    };
    const updateNameAndShortName = (copyOfCourse, isUltraCourse, isCopy, academicYearCode) => {
        return {
            name: !isCopy ? '' : trimAcademicYearFromPrefix(copyOfCourse.name),
            shortName: !isCopy ? '' : calcCourseShortName(
                trimAcademicYearFromSuffix(copyOfCourse.courseId),
                isUltraCourse,
                isCopy,
                academicYearCode
            ),
        };
    };

    const onIsUltraCourse = React.useCallback(isUltraCourse => {
        setFormData(prev => ({
            ...prev,
            isUltraCourse,
            ...updateNameAndShortName(prev.copyOfCourse || {}, isUltraCourse, prev.type === 'Copy', prev.academicYear?.code)
        }));
    }, []);

    const onIsCopyChange = React.useCallback(isCopy => {
        setFormData(prev => ({
            ...prev,
            type: isCopy ? "Copy" : "New",
            ...updateNameAndShortName(prev.copyOfCourse || {}, prev.isUltraCourse, isCopy, prev.academicYear?.code)
        }));
    }, []);

    const onCopyOfCourseChange = React.useCallback(copyOfCourse => {
        setFormData(prev => ({
            ...prev,
            copyOfCourse,
            ...updateNameAndShortName(copyOfCourse || {}, prev.isUltraCourse, prev.type === 'Copy', prev.academicYear?.code)
        }));
    }, []);

    const onAcademicYearChange = React.useCallback(academicYear => {
        setFormData(prev => ({
            ...prev,
            academicYear,
            ...updateNameAndShortName(prev.copyOfCourse || {}, prev.isUltraCourse, prev.type === 'Copy', academicYear?.code)
        }))
    }, []);

    const onNameChange = React.useCallback(name => setFormData(prev => ({ ...prev, name })), []);
    const onShortNameChange = React.useCallback(shortName => setFormData(prev => ({ ...prev, shortName: shortName?.toUpperCase().replace(/\s+/g, "-").replace(/[^A-Z0-9-]/g, '') })), []);
    const onDepartmentChange = React.useCallback(department => setFormData(prev => ({ ...prev, department, departmentTemplate: null })), []);
    const onDepartmentTemplateChange = React.useCallback(departmentTemplate => setFormData(prev => ({ ...prev, departmentTemplate })), []);
    const onSemesterChange = React.useCallback(semester => setFormData(prev => ({ ...prev, semester })), []);
    const onPurposeChange = React.useCallback(purpose => setFormData(prev => ({ ...prev, purpose, purposeOther: null })), []);
    const onPurposeOtherChange = React.useCallback(purposeOther => setFormData(prev => ({ ...prev, purposeOther })), []);
    const onUserDataChange = React.useCallback(userData => setFormData(prev => ({ ...prev, userData })), []);
    const onDiscussChange = React.useCallback(discuss => setFormData(prev => ({ ...prev, discuss })), []);
    const onCommentsChange = React.useCallback(comments => setFormData(prev => ({ ...prev, comments })), []);    
  
    const [submitted, setSubmitted] = React.useState(false);

    /** @type {[Joi.ValidationResult, React.Dispatch<Joi.ValidationResult>]} */
    const [validation, setValidation] = React.useState(null);

    const handleSubmit = React.useCallback(e => {
        e.preventDefault();
        const stopWait = waitFor("REQUEST_SUBMIT");
        const result = validateFormData(formData);
        setValidation(result);

        if (Boolean(result.error)) {
            stopWait();
            danger("Please check you have provided all the information required and then select Submit.")
        } else {
            const postData = {
                isUltraCourse: formData.isUltraCourse,
                type: formData.type,
                isCopy: formData.isCopy,
                academicYearCode: formData.academicYear?.code || null,
                courseId: joinStrings("-", [formData.shortName, formData.academicYear?.code]),
                courseName: joinStrings("-", [formData.academicYear?.code, formData.name]),
                departmentCode: formData.department.code,
                departmentTemplateCode: formData.departmentTemplate?.code || null,
                semesterCode: formData.semester?.id || null,
                courseUse: formData.purpose.id,
                courseUseOtherDetails: formData.purposeOther || null,
                containsUserData: formData.userData || false,
                wouldLikeToDiscuss: formData.discuss || false,
                additionalNotes: formData.comments || null,
                copyOfCourseId: formData.copyOfCourse?.courseId || null,
                copyOfCourseName: formData.copyOfCourse?.name || null
            }

            const headers = new Headers();
            headers.append("Content-Type", "application/json")
            fetchApi("/requests", { method: "POST", body: JSON.stringify(postData), headers })
                .then(response => {
                    if (response.ok) {
                        setSubmitted(true);
                        info("Thankyou for using this service. Your course request has been submitted. Please wait for an member of the support team to get in touch about this request.");
                    } else {
                        throw new CoursesError("Could not contact Requests API: code " + response.status);
                    }
                })
                .catch(handleError)
                .finally(stopWait)
        }
    }, [danger, fetchApi, formData, handleError, info, waitFor]);

    const [shouldResetDepartments, setShouldResetDepartments] = React.useState(false);

    const handleReset = React.useCallback(e => {
        setFormData(initialFormData);
        setValidation(null);
        setSubmitted(false);
        setShouldResetDepartments(true);
    }, []);

    const handleDepartmentsResetComplete = React.useCallback(() => {
        setShouldResetDepartments(false);
    }, [])

    return <Form onSubmit={handleSubmit}>
        <NoticeArea />
        {submitted && <FormGroup><Button color="default" onClick={handleReset}>Request another course</Button></FormGroup>}
        <IsUltraCourse
            id="isUltraCourse"
            label="Do you want to create an ultra or original course?"
            value={formData.isUltraCourse}
            onChange={onIsUltraCourse}
            disabled={submitted || waiting}
            error={hasError(validation, ["isUltraCourse"]) ? "Please choose whether this is an Ultra or Original course" : null}
        />
        <IsCopyRequest
            id="isCopy"
            label="Do you want to copy an existing course?"
            value={formData.type === "Copy"}
            onChange={onIsCopyChange}
            disabled={submitted || waiting}
        />        
        <CourseToCopy
            url="/instructorcourses"
            id="courseToCopy"
            label="Select the course you wish to copy"
            value={formData.copyOfCourse}
            onChange={onCopyOfCourseChange}
            disabled={submitted || waiting}
            hidden={formData.type !== "Copy"}
        />
        <AcademicYears
            id="academicYear"
            label="Academic year"
            noValue="No academic year"
            value={formData.academicYear}
            onChange={onAcademicYearChange}
            disabled={submitted || waiting}
        />
        <input
            id="courseShortNameValue"
            type="hidden"
            readonly
            value={formData.copyOfCourse ? trimAcademicYearFromSuffix(formData.copyOfCourse.courseId) : ''}
            ref={courseShortNameRef}
        />
        <input
            id="academicYearCodeValue"
            type="hidden"
            readonly
            value={formData.academicYear?.code}
            ref={academicYearCodeRef}
        />
        <InputPrependAppend
            id="name"
            label="Course title"
            type="text"
            separator="-"
            placeholder="Descriptive course title"
            prepend={formData.academicYear?.code}
            value={formData.name}
            onChange={onNameChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["name"]) ? "You must provide a valid course title" : null}
        />
        <InputPrependAppend
            id="shortName"
            label="Course ID"
            type="text"
            separator="-"
            placeholder="COURSE-ID"
            description="*Uppercase numbers and dashes only"
            prepend={formData.isUltraCourse? "CM" : ""}
            append={formData.academicYear?.code}
            value={formData.shortName}
            onChange={onShortNameChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["shortName"]) ? "You must provide a valid course ID" : null}
        />
        <Departments
            id="department"
            url="/faculties"
            value={formData.department}
            onChange={onDepartmentChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["department"]) ? "You must provide a valid faculty, school or department" : null}
            shouldReset={shouldResetDepartments}
            onResetComplete={handleDepartmentsResetComplete}
        />
        <Collapse isOpen={!formData.isUltraCourse}>
            <DepartmentTemplates
                id="departmentTemplate"
                url="/templates"
                code={formData.department?.templateCode}
                value={formData.departmentTemplate}
                onChange={onDepartmentTemplateChange}
                disabled={submitted || waiting}
                />
        </Collapse>
        <hr />
        <Semesters
            id="semester"
            url="/semesters"
            label="Semester of delivery"
            noValue="Not applicable"
            value={formData.semester}
            onChange={onSemesterChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["semester"]) ? "You must specify a semester" : null}
        />
        <Purposes
            url="/coursepurposes"
            id="purpose"
            label="What will your course be used for?"
            value={formData.purpose}
            onChange={onPurposeChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["purpose"]) ? "You must specify a course use" : null}
        />
        <PurposeOther
            id="purposeOther"
            label={<strong>*Please specify other use</strong>}
            placeholder="Other"
            show={formData.purpose?.furtherDetailRequired}
            value={formData.purposeOther}
            onChange={onPurposeOtherChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["purposeOther"]) ? "You must specify a the other course use" : null}
        />
        <hr />
        <UserData
            id="userData"
            label="Will this course record user data such as grades, assessment submissions, participation in discussion boards etc?"
            value={formData.userData}
            onChange={onUserDataChange}
            disabled={submitted || waiting}
            error={hasError(validation, ["userData"]) ? "You must specify whether this course records user data" : null}
        />
        <Discuss
            id="discuss"
            label="Would you like to discuss the setup of your arbitrary course with a member of Education Services?"
            value={formData.discuss}
            onChange={onDiscussChange}
            disabled={submitted || waiting}
        />
        <InputPrependAppend
            id="comments"
            label="Do you have any other comments or requirements?"
            type="textarea"
            placeholder="Additional comments"
            value={formData.comments}
            onChange={onCommentsChange}
            disabled={submitted || waiting}
        />
        <Button className="float-right" type="submit" color="primary" disabled={submitted || waiting}>Submit</Button>
    </Form>
}

export default React.memo(Courses);