import PropTypes from "prop-types";
import Joi from "joi";
import React from "react"
import { FormError } from "..";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import InputPrependAppend from "../InputPrependAppend";
import { withAbort } from "../../../util";

/** 
 * @typedef TermModel
 * @property {String} id
 * @property {String} name
 * @property {Boolean} isDefault
 * @property {String} shortName
 */

const termSchema = Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    shortName: Joi.string().allow(null).optional(),
    isDefault: Joi.boolean().allow(null).optional()
});

/**
 * Validate the terms array
 *
 * @param {*} terms
 * @return {Array<TermModel>} 
 */
const validateTerms = terms => {
    const result = Joi.array().items(termSchema).validate(terms);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Terms Response", result);
    }
    return terms;
}

const Terms = props => {

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    /** @type {[Array<TermModel>, React.Dispatch<Array<TermModel>>]} */
    const [terms, setTerms] = React.useState(null);

    const { url, onChange, onReady } = props;
    // todon: query all terms for UltraFromOriginal
    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("INIT_TERMS");
        fetchApi(url, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateTerms)
                        .then(termsTemp => {
                            setTerms(termsTemp);
                            onChange(termsTemp.find(term => term.isDefault));
                        })
                } else {
                    throw new FormError("Could not contact Terms API");
                }
            })
            .then(() => onReady && onReady())
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [fetchApi, handleError, onChange, onReady, url, waitFor]);

    const handleOnChange = React.useCallback(value =>
        onChange(terms.find(term => term.id === value) || null),
        [terms, onChange]
    );

    return <InputPrependAppend type="select" label={props.label} id={props.id} value={props.value?.id || ""} onChange={handleOnChange} disabled={props.disabled}>
        {terms?.map(term =>
            <option key={term.id} value={term.id}>{term.name}</option>
        )}
        <option value="">{props.noValue}</option>
    </InputPrependAppend>
}

Terms.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    noValue: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onReady: PropTypes.func
}

export { termSchema };
export default React.memo(Terms);
