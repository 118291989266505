import React from "react";
import { useLocation } from "react-router";
import { Col, Row } from "reactstrap";

const titleDefs = [
  {
    path: "/sandbox",
    title: "Sandbox",
    subTitle: "Create or delete your blackboard sandbox course",
  },
  {
    path: "/ultrasandbox",
    title: "Sandbox",
    subTitle: "Create or delete your blackboard sandbox course",
  },
  {
    path: "/ultraexemplar",
    title: "Exemplar course",
    subTitle: "Create or delete your blackboard Ultra exemplar course",
  },
  {
    path: "/courses",
    title: "Arbitrary Courses",
    subTitle:
      "View your arbitrary courses, create a new one or clone an old one",
  },
  {
    path: "/programme-courses",
    title: "Programme Courses",
    subTitle: "View your programme courses or create a new one",
  },
  {
    path: "/approvals",
    title: "Arbitrary Course Approvals",
    subTitle: "View pending course requests, approve them or reject them",
  },
  {
    path: "/home",
    title: "Course Builder Home",
    subTitle: "Welcome to the Blackboard course builder homepage",
  },
  {
    path: "/",
    title: "Blackboard Course Builder",
    subTitle: "Please Login",
  },
];

const Title = () => {
  const location = useLocation();

  const titleDef = titleDefs.find((def) =>
    location.pathname.startsWith(def.path)
  );

  return (
    <Row className="branding small">
      <Col>
        <a href="/home">
          <h1>{titleDef?.title}</h1>
        </a>
        <small>{titleDef?.subTitle}</small>
      </Col>
    </Row>
  );
};

export default Title;
