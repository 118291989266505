import React from "react";
import NoticeArea from "../../../NoticeArea";
import SandboxButtons from "../../../FormItems/SandboxButtons";



const UltraExemplar = () => {
    return <React.Fragment>
        <h2>Blackboard Ultra exemplar courses</h2>
        <NoticeArea />
        <p>You can create a copy of a course designed to demonstrate good practice with Blackboard features.</p>
        <ul>
            <li>You can only have one such course.</li>
            <li>Exemplar courses can <strong>not</strong> be used for teaching (any undergraduate or postgraduate taught students enrolled on it will be removed).</li>
            <li>Your exemplar course will remain open while you remain at the University unless you explicitly remove it.</li>
        </ul>

        <h3>Your Ultra exemplar course</h3>

        <SandboxButtons apiEndpoint="/ultraexemplar" sandboxDescription="exemplar course"/>
    </React.Fragment >
}

export default React.memo(UltraExemplar);