import PropTypes from "prop-types";
import React from "react";
import { Input, Row, Col, Label } from "reactstrap";


const DepartmentFilters = (props) => {
    const { faculties, onChange } = props;

    const [departmentFilter, setDepartmentFilter] = React.useState("");
    const [schoolFilter, setSchoolFilter] = React.useState("");
    const [facultyFilter, setFacultyFilter] = React.useState("");

    const updateFacultyFilter = function (code) {
        if (code != "") {
            setDepartmentFilter("");
            setSchoolFilter("");
        }
        setFacultyFilter(code);
    }
    const updateSchoolFilter = function (code) {
        if (code != null) {
            setDepartmentFilter("");
            setSchoolFilter(code);
        }
    }

    React.useEffect(() => {
        onChange(facultyFilter, schoolFilter, departmentFilter)
    }, [departmentFilter, schoolFilter, facultyFilter])

  return (
    <Row>
        <Col>
            <Label>Faculty filter
            <Input type="select"
                value={facultyFilter}
                onChange={e => updateFacultyFilter(e.target.value)}>
                <option value="">All faculties</option>
                {
                    faculties && faculties.map(f =>
                        <option value={f.code}> {f.name} ({f.code})</option>)
                }
            </Input>
            </Label>
        </Col>
        <Col>
            <Label>School filter
            <Input type="select"
                value={schoolFilter}
                onChange={e => updateSchoolFilter(e.target.value)} >
                <option value="">All schools</option>
                {
                    faculties && faculties
                        .filter(f => facultyFilter == "" || f.code == facultyFilter)
                        .map(f =>
                            <optgroup label={f.name}>
                                {
                                    f.schools.map(s =>
                                        <option value={s.code}> {s.name} ({s.code})</option>
                                    )
                                }
                            </optgroup>
                        )
                }
            </Input>
            </Label>
        </Col>
        <Col>
            <Label>Department filter
            <Input type="select"
                value={departmentFilter}
                onChange={e => setDepartmentFilter(e.target.value)} >
                <option value="">All departments</option>
                {
                    faculties && faculties
                        .filter(f => facultyFilter == "" || f.code == facultyFilter)
                        .map(f =>
                            f.schools
                                .filter(s => schoolFilter == "" || s.code == schoolFilter)
                                .map(s => 
                                    <optgroup label={f.name + "/" + s.name}>
                                        {
                                            s.departments.map(d =>
                                                <option value={d.code}> {d.name} ({d.code})</option>
                                            )
                                        }
                                    </optgroup>
                                )
                        )
                }
            </Input>
            </Label>
        </Col>
    </Row>
  );
};

DepartmentFilters.propTypes = {
    faculties: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default React.memo(DepartmentFilters);
