import React from "react";
import { Alert, Collapse } from "reactstrap";
import { Notice } from "../Store"

const NoticeArea = () => {

    const { notice, dismiss } = React.useContext(Notice);

    /** @type {React.RefObject<HTMLElement} */
    const ref = React.createRef();

    const scrollToNotice = () => {
        if (Boolean(notice)) {
            window.setTimeout(() => ref.current && window.scrollTo({ top: ref.current.offsetTop, left: 0, behavior: 'smooth' }), [500]);
        }
    }
    React.useEffect(scrollToNotice, [notice, ref])

    return <div ref={ref}>
        <Collapse isOpen={Boolean(notice)}>
            <Alert color={notice?.type || "info"} isOpen={Boolean(notice)} toggle={dismiss}>
                {notice?.message}
            </Alert>
        </Collapse>
    </div>
}

export default React.memo(NoticeArea);