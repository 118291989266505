import React from "react";
import "./App.css"
import Accessibility from "./components/Accessability";
import Title from "./components/Title";
import { Container } from "reactstrap";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Store from "./components/Store";
import ViewContainer from "./components/ViewContainer";

const App = () => <Store>
  <Container>
    <Accessibility />
    <Title />
    <Content>
      <ViewContainer />
    </Content>
    <Footer />
  </Container>
</Store>

export default React.memo(App);
