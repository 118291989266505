import React from "react";

const launchToolbar = e => {
    const d = document;
    const jf = d.createElement('script');
    jf.src = ('https:' === document.location.protocol ? 'https://ssl.atbar.org/c' : 'http://c.atbar.org') + '/ATBar2/ATBar.min.user.js';
    jf.type = 'text/javascript';
    jf.id = 'ToolBar';
    d.getElementsByTagName('head')[0].appendChild(jf);
    e.preventDefault();
};

const ToolbarLaunch = () => <a id="toolbar-launch" title="Launch the Accessibility Toolbar to adjust this webpage, have it read aloud and other functions." onClick={launchToolbar} href="#toolbar">Accessibility Tools</a>

export default React.memo(ToolbarLaunch);