import PropTypes from "prop-types";
import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import CourseRequestList from "./CourseRequestList";
import Approval from "./Approval";
import ProgrammeCourseForm from "../ProgrammeCourses/ProgrammeCourseForm";

const Approvals = props => <div>
    <Switch>
        <Route exact sensitive path={props.path}>
            <CourseRequestList path={props.path} />
        </Route>
        <Route sensitive path={`${props.path}/arbitrary-course/:requestId(\\d+)`}>
            <Approval back={props.path} />
        </Route>
        <Route sensitive path={`${props.path}/programme-course/:requestId(\\d+)`}>
            <ProgrammeCourseForm back={props.path} isApprovalView />
        </Route>
        <Route sensitive path={props.path}>
            <Redirect to={props.path} />
        </Route>
    </Switch>
</div>

Approvals.propTypes = {
    path: PropTypes.string.isRequired
}

export default React.memo(Approvals);