import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import AuthenticationProvider from "./Authentication";
import WaitingProvider from "./Waiting";
import NoticeProvider from "./Notice";
import ErrorHandlerProvider from "./ErrorHandler";

const Store = props =>
    <Router>
        <NoticeProvider>
            <ErrorHandlerProvider>
                <WaitingProvider>
                    <AuthenticationProvider>
                        {props.children}
                    </AuthenticationProvider>
                </WaitingProvider>
            </ErrorHandlerProvider>
        </NoticeProvider>
    </Router>

Store.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export { Context as Authentication, Role, NotLoggedInError } from "./Authentication";
export { Context as Waiting } from "./Waiting";
export { Context as Notice } from "./Notice";
export { Context as ErrorHandler, HandledError, IgnoredError } from "./ErrorHandler";
export default React.memo(Store);
