import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const UserData = props => {

    const { onChange } = props;

    const handleChange = React.useCallback(e => onChange("yes" === e.target.value), [onChange]);

    return <FormGroup tag="fieldset">
        <Label>{props.label}</Label>
        <FormGroup check>

            <Label check>
                <Input type="radio" id={`${props.id}-yes`} value="yes" onChange={handleChange} checked={props.value === true} disabled={props.disabled} invalid={Boolean(props.error)} />{' '}
                Yes
            </Label>
        </FormGroup>
        <FormGroup check>
            <Label check>
                <Input type="radio" id={`${props.id}-no`} value="no" onChange={handleChange} checked={props.value === false} disabled={props.disabled} invalid={Boolean(props.error)} />{' '}
                No
            </Label>
        </FormGroup>
        <small className="text-danger" id={`${props.id}-error`}>{props.error}</small>
    </FormGroup>
}

UserData.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string
}

export default React.memo(UserData);