import PropTypes from "prop-types";
import Joi from "joi";
import React from "react"
import InputPrependAppend from "../InputPrependAppend";

/** 
 * @typedef AcademicYearModel
 * @property {String} code
 * @property {String} name
 * @property {Boolean} isDefault
 */

const academicYearSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    isDefault: Joi.boolean().allow(null).optional()
});

const getCurrentAndNextAcademicYears = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    let startYear, endYear;

    // Academic year starts in August
    if (currentMonth >= 7) { // August is month 7 (0-indexed)
        startYear = currentYear;
        endYear = currentYear + 1;
    } else {
        startYear = currentYear - 1;
        endYear = currentYear;
    }

    const currentAcademicYear = `${startYear}-${String(endYear).slice(2)}`;
    const currentAcademicYearCode = `${String(startYear).slice(2)}-${String(endYear).slice(2)}`;
    const nextAcademicYear = `${endYear}-${String(endYear + 1).slice(2)}`;
    const nextAcademicYearCode = `${String(endYear).slice(2)}-${String(endYear + 1).slice(2)}`;

    return [
        { code: currentAcademicYearCode, name: currentAcademicYear, isDefault: false },
        { code: nextAcademicYearCode, name: nextAcademicYear, isDefault: false }
    ];
};

const AcademicYears = props => {
    const [academicYears, setAcademicYears] = React.useState([]);

    React.useEffect(() => {
        const years = getCurrentAndNextAcademicYears();
        setAcademicYears(years);
    }, []);

    const handleOnChange = (chosenAcademicYearCode) => {
        const selectedYear = academicYears.find(year => year.code === chosenAcademicYearCode);
        props.onChange(selectedYear);
    };

    return (
        <InputPrependAppend
            type="select"
            label={props.label}
            id={props.id}
            value={props.value?.code || ""}
            onChange={handleOnChange}
            disabled={props.disabled}
        >
            {academicYears.map(academicYear => (
                <option key={academicYear.code} value={academicYear.code}>{academicYear.name}</option>
            ))}
            <option value="">{props.noValue}</option>
        </InputPrependAppend>
    );
}

AcademicYears.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    noValue: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onReady: PropTypes.func
}

export { academicYearSchema };
export default React.memo(AcademicYears);
