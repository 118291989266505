import PropTypes from "prop-types";
import React from "react";
import { Table } from "reactstrap";

const RequestOverview = props => {

    if (props.type === "Copy") {
        return <Table dark>
            <tbody>
                <tr>
                    <th scope="row">Request for</th>
                    <td>Copy of {props.copyOfCourseId} ({props.copyOfCourseName})</td>
                </tr>
                <tr>
                    <th scope="row">Requested by</th>
                    <td>{props.requestedBy}</td>
                </tr>
                <tr>
                    <th scope="row">on date</th>
                    <td>{new Date(props.requestDate).toLocaleString('en-GB')}</td>
                </tr>
            </tbody>
        </Table>    }
    else {
        return <Table dark>
            <tbody>
                <tr>
                    <th scope="row">Request for</th>
                    <td>New course</td>
                </tr>
                <tr>
                    <th scope="row">Requested by</th>
                    <td>{props.requestedBy}</td>
                </tr>
                <tr>
                    <th scope="row">on date</th>
                    <td>{new Date(props.requestDate).toLocaleString('en-GB')}</td>
                </tr>
            </tbody>
        </Table>
    }
}

RequestOverview.propTypes = {
    type: PropTypes.string,
    requestedBy: PropTypes.string,
    requestDate: PropTypes.string,
    copyOfCourseId: PropTypes.string,
    copyOfCourseName: PropTypes.string
}



export default React.memo(RequestOverview);