import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";
import { Role } from "../Store";
import LoginPage from "./views/LoginPage";
import Sandbox from "./views/Sandbox";
import UltraSandbox from "./views/UltraSandbox";
import UltraExemplar from "./views/UltraExemplar";
import Courses from "./views/Courses";
import Approvals from "./views/Approvals";
import Programmes from "./views/ProgrammeCourses";
import HomePage from "./views/HomePage";

const PATHS = {
    SANDBOX: "/sandbox",
    ULTRA_SANDBOX: "/ultrasandbox",
    ULTRA_EXEMPLAR: "/ultraexemplar",
    COURSES: "/courses",
    PROGRAMME_COURSES: "/programme-courses",
    APPROVALS: "/approvals",
    HOME_PAGE: "/home",
};

const ViewContainer = () => (
  <Switch>
    <Route exact sensitive path="/">
      <LoginPage />
    </Route>
    <ProtectedRoute
      sensitive
      path={PATHS.SANDBOX}
      role1={Role.STAFF}
      role2={Role.ADMIN}
      referer={PATHS.SANDBOX}
    >
      <Sandbox />
    </ProtectedRoute>
    <ProtectedRoute
      sensitive
      path={PATHS.ULTRA_SANDBOX}
      role1={Role.STAFF}
      role2={Role.ADMIN}
      referer={PATHS.ULTRA_SANDBOX}
    >
      <UltraSandbox />
    </ProtectedRoute>
    <ProtectedRoute
      sensitive
      path={PATHS.ULTRA_EXEMPLAR}
      role1={Role.STAFF}
      role2={Role.ADMIN}
      referer={PATHS.ULTRA_EXEMPLAR}
    >
      <UltraExemplar />
    </ProtectedRoute>
    <ProtectedRoute
      sensitive
      path={PATHS.COURSES}
      role1={Role.STAFF}
      role2={Role.ADMIN}
      referer={PATHS.COURSES}
    >
      <Courses />
    </ProtectedRoute>
    <ProtectedRoute
      sensitive
      path={PATHS.PROGRAMME_COURSES}
      role1={Role.STAFF}
      role2={Role.ADMIN}
      referer={PATHS.PROGRAMME_COURSES}
    >
      <Programmes path={PATHS.PROGRAMME_COURSES} />
    </ProtectedRoute>
    <ProtectedRoute
      sensitive
      path={PATHS.APPROVALS}
      role1={Role.ADMIN}
      referer={PATHS.APPROVALS}
    >
      <Approvals path={PATHS.APPROVALS} />
    </ProtectedRoute>
    <ProtectedRoute
      sensitive
      path={PATHS.HOME_PAGE}
      role1={Role.STAFF}
      role2={Role.ADMIN}
      referer={PATHS.HOME_PAGE}
    >
      <HomePage />
    </ProtectedRoute>
    <Route sensitive path="/">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default React.memo(ViewContainer);
