import PropTypes from "prop-types";
import React from "react";
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { HelpIcon } from '..';

const InputPrependAppend = props => {

    const { onChange, prepend, append, separator, label, value, error, ...otherProps } = props;
    const handleOnChange = React.useCallback(e => onChange(e?.target?.value), [onChange]);

    return <FormGroup>
        <Label for={props.id}>{label}
            {Boolean(props.help) && <HelpIcon id={`${props.id}-help`}>{props.help}</HelpIcon>}
        </Label>
        <InputGroup>
            {props.prepend &&
                typeof props.prepend === "string" ? <InputGroupText>{props.prepend}{props.separator}</InputGroupText> : props.prepend
            }
            <Input {...otherProps} onChange={handleOnChange} value={value || ""} invalid={Boolean(error)}>
                {props.children}
            </Input>
            {props.append &&
                    typeof props.append === "string" ? <InputGroupText>{props.separator}{props.append}</InputGroupText> : props.append
            }
            
        <FormFeedback id={`${props.id}-error`}>{error}</FormFeedback>
        </InputGroup>
        {props.description && <small>{props.description}</small>}
    </FormGroup >
}

InputPrependAppend.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    separator: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.string,
    help: PropTypes.string
}

export default React.memo(InputPrependAppend);
